<template>
  <div class="bgcard">
    <p>{{ $t("lang.ServerDetails") }}</p>
    <div>
      <div class="information">
        <div class="basic">
          <div>{{ $t("lang.EssentialInformation") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input">
            <label>{{ $t("lang.ServerName") }}：</label>
            <span>{{ serverdata.name }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.ServerType") }}：</label>
            <span v-if="serverdata.modelType == 1">{{
              $t("lang.Cloudserver")
            }}</span>
            <span v-if="serverdata.modelType == 2">{{
              $t("lang.Localserver")
            }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.ServerSerial") }}：</label>
            <span>{{ serverdata.mid }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.Location") }}：</label>
            <span>{{ serverdata.location }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.ServerNetwork") }}：</label>
            <span>{{ serverdata.network }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.RunningState") }}：</label>
            <span v-if="serverdata.sysStat == 0">{{ $t("lang.Deleted") }}</span>
            <span v-if="serverdata.sysStat == 1">{{ $t("lang.normal") }}</span>
            <span v-if="serverdata.sysStat == 2">{{ $t("lang.fault") }}</span>
            <span v-if="serverdata.sysStat == 3">{{
              $t("lang.maintain")
            }}</span>
            <span v-if="serverdata.sysStat == 4">{{ $t("lang.offline") }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.BusinessState") }}：</label>
            <span v-if="serverdata.sysStat == 1">
              <span v-if="serverdata.process.stat == 0">{{
                $t("lang.normal")
              }}</span>
              <span v-else>{{ $t("lang.abnormal") }}</span>
            </span>
             <span v-else>
              <span v-if="serverdata.stat == 0">{{
                $t("lang.normal")
              }}</span>
              <span v-else>{{ $t("lang.abnormal") }}</span>
            </span>
          </div>
          <div class="basic_input" v-if="serverdata.type == 2">
            <label>{{ $t("lang.LeaseTime") }}：</label>
            <span v-if="serverdata.startTime || serverdata.endTime"
              >{{ serverdata.startTime }}~{{ serverdata.endTime }}</span
            >
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.OnlineTerminal") }}：</label>
            <a href="javascript:;"
              ><span @click="terminal" style="color: rgb(16, 97, 197)">{{
                serverdata.onlineDevs
              }}</span></a
            >
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.Operationtype") }}：</label>
            <span v-if="serverdata.type">{{
              serverdata.type == 2
                ? $t("lang.lease")
                : serverdata.type == 3
                ? $t("lang.sale")
                : ""
            }}</span>
          </div>
        </div>
      </div>
      <div class="information">
        <div class="basic">
          <div>{{ $t("lang.ConfigureInformation") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input">
            <label>{{ $t("lang.IpAddress") }}：</label>
            <span>{{ serverdata.ip }}</span>
          </div>
          <!-- <div class="basic_input">
						<label>{{$t('lang.VisitAddress')}}：</label>
						<span>{{serverdata.accessIP}}</span>
					</div> -->
          <div class="basic_input">
            <label>{{ $t("lang.port") }}：</label>
            <span>{{ serverdata.port }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.CpuNumber") }}：</label>
            <span>{{ serverdata.corenum }} {{ $t("lang.nucleus") }}</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.broadband") }}：</label>
            <span>{{ serverdata.bwused }}/{{ serverdata.bwtotal }} M</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.disk") }}：</label>
            <span
              >{{
                (Number(serverdata.storageTotal) / 1024 / 1024).toFixed(2)
              }}
              G</span
            >
            <!-- <span>{{ serverdata.storageTotal }} G</span> -->
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.Memory") }}：</label>
            <span
              >{{
                (Number(serverdata.memTotal) / 1024 / 1024).toFixed(2)
              }}
              G</span
            >
            <!-- <span>{{ serverdata.memTotal }} G</span> -->
          </div>
        </div>
      </div>
      <div class="information" v-if="serverdata.sysStat == 1">
        <div class="basic">
          <div>{{ $t("lang.ResourceStatus") }}</div>
          <span></span>
        </div>
        <div class="basic_a">
          <div class="basic_input">
            <label>{{ $t("lang.Cpuutilization") }}：</label>
            <span>{{ serverdata.cpu }}%</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.shangxiang") }}：</label>
            <span>{{ serverdata.flowSend }} M</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.xiaxing") }}：</label>
            <span>{{ serverdata.flowRecv }} M</span>
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.Occupieddisk") }}：</label>
            <span
              >{{
                (Number(serverdata.storageUsed) / 1024 / 1024).toFixed(2)
              }}/{{
                (Number(serverdata.storageTotal) / 1024 / 1024).toFixed(2)
              }}
              G</span
            >
          </div>
          <div class="basic_input">
            <label>{{ $t("lang.Occupiedmemory") }}：</label>
            <!-- <span>{{ serverdata.memUsed }}G/{{serverdata.memTotal}} G</span> -->
            <span
              >{{ (Number(serverdata.memUsed) / 1024 / 1024).toFixed(2) }}/{{
                (Number(serverdata.memTotal) / 1024 / 1024).toFixed(2)
              }}
              G</span
            >
          </div>
        </div>
      </div>
      <div class="btnstyle">
        <button @click="returns">{{ $t("lang.return") }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import * as server from "@/api/server.js";
export default {
  data() {
    return {
      serverdata: {},
      page: 0,
      mid: "",
      times: null,
    };
  },
  created() {
    this.mid = JSON.parse(this.$route.query.data).mid;
    this.page = this.$route.query.page;
    this.serverDetail();
    this.times = setInterval(() => {
      this.serverDetail();
    }, 5000);
  },
  destroyed() {
    clearTimeout(this.times);
  },
  methods: {
    returns() {
      this.$router.push({
        path: "serverlist?page=" + this.page,
      });
    },

    async serverDetail() {
      let res = await server.serverDetail(this.mid);
      console.log(res);
      if (res.data) {
        if (res.data.data.sysStat === 1) {
          let serverdata = res.data.data.message;
          serverdata.ip = res.data.data.ip;
          serverdata.port = res.data.data.port;
          serverdata.type = res.data.data.type;
          serverdata.location = res.data.data.location;
          serverdata.network = res.data.data.network;
          serverdata.name = res.data.data.name;
          serverdata.modelType = res.data.data.modelType;
          serverdata.startTime = res.data.data.startTime;
          serverdata.endTime = res.data.data.endTime;
          this.serverdata = serverdata;
        } else {
          this.serverdata = res.data.data;
        }
      } else {
        this.tableData = [];
      }
    },
    //在线终端数
    terminal() {
      this.$router.push({
        path: "terminallist",
        query: { mid: this.serverdata.mid },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.bgcard {
  border: 0px;
  box-sizing: border-box;
  padding: 0.3rem;
  border-radius: 0.02rem;
  background: #222628;

  p {
    color: #dbdbdb;
    font-size: 0.18rem;
    font-weight: bold;
  }

  .information {
    margin-top: 0.37rem;
  }
}

.basic {
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;

  div {
    color: #dbdbdb;
    font-size: 0.16rem;
  }

  span {
    margin-left: 0.14rem;
    width: 80%;
    border-top: 0.01rem solid #797979;
  }
}

.basic_a {
  width: 80%;
  margin-left: 0.78rem;
  font-size: 0.15rem;
  display: flex;
  flex-flow: row wrap;

  .basic_input {
    width: 34%;
    margin-top: 0.25rem;
    display: flex;
    align-items: center;
    margin-right: 0.8rem;

    label {
      color: #dbdbdb;
      font-weight: bold;
    }

    span {
      color: #dbdbdb;
    }
  }
}

.btnstyle {
  margin-top: 0.5rem;

  button {
    outline-style: none;
    border: none;

    &:first-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #ffffff;
      margin-right: 0.16rem;
    }

    &:last-of-type {
      width: 1.31rem;
      height: 0.54rem;
      background: #222628;
      border: 0.01rem solid #307b37;
      border-radius: 0.04rem;
      font-size: 0.18rem;
      font-family: PingFang SC;
      font-weight: 500;
      color: #307b37;
    }
  }
}
</style>
